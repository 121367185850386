<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin uk-margin-auto">
            <div class="uk-flex uk-margin uk-width-1-4">
                <button class="uk-button uk-button-default uk-width-expand" style="background:white" type="button">{{ filterDate() }}</button>
                <div id="date-dropdown" uk-dropdown="mode: click;pos: right-top">
                    <ul class="uk-dropdown-grid uk-child-width-auto" uk-grid>
                        <div>
                            <datetime
                                    id="start_date"
                                    input-class="uk-button"
                                    v-model="meta.start_date"
                                    :max-datetime="new Date().toISOString()"
                                    placeholder="Start Date"
                            ></datetime>
                        </div>
                        <template v-if="meta.start_date">
                            <div class="uk-text-center uk-margin-auto-vertical">-</div>
                            <div>
                                <datetime
                                        id="end_date"
                                        input-class="uk-button"
                                        v-model="meta.end_date"
                                        :min-datetime="meta.start_date"
                                        :max-datetime="new Date().toISOString()"
                                        placeholder="End Date"
                                ></datetime>
                            </div>
                            <div>
                                <button class="uk-button uk-button-primary uk-width-auto" type="button" v-if="meta.start_date" @click="setDate()">Apply</button>
                            </div>
                        </template>
                    </ul>
                </div>
                <button class="uk-button-small uk-button-danger uk-width-auto" type="button" v-if="meta.start_date" @click="resetDate()"><span uk-icon="icon: close"></span></button>
            </div>
            <div>
                <span class="uk-button uk-button-primary uk-border-rounded" @click="downloadExcelDetail">Download</span>
            </div>
        </div>
        <div class="uk-card uk-card-default uk-margin uk-margin-auto">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-table-expand uk-text-center">Fullname</th>
                            <th class="uk-text-center">Create</th>
                            <th class="uk-text-center">Modify</th>
                        </tr>
                    </thead>
                    <tbody v-if="reports.length>0">
                        <tr v-for="(report, i) in reports" :key="i">
                            <td class="uk-text-center">{{report["Fullname"]}}</td>
                            <td class="uk-text-center">{{report["Create"]}}</td>
                            <td class="uk-text-center">{{report["Modify"]}}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="3"/>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { excelDownloader } from '@/utils/helper';
import { Datetime } from 'vue-datetime';

export default {
    data(){
        return {
            meta: {
                start_date: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(), // 7 days ago
                end_date: null
            },
            reports: [],
            isLoading: false,
            salary_detail: null,
            formSalary: {
                _id: null,
                status: null
            },
        };
    },
    components: {
        EmptyTable,
        Datetime
    },
    async mounted() {
        await this.getEntry();
    },
    methods: {
        ...mapActions({
            getEntryReport: 'report/getEntryReport',
        }),
        async getEntry(){
            this.reports = await this.getEntryReport(this.meta);
        },
        async setDate() {
            await this.getEntry();
            await window.UIkit.dropdown('#date-dropdown').hide();
        },
        async resetDate() {
            this.meta.start_date = null;
            this.meta.end_date = null;
            await this.getEntry();
        },
        formatDate(data) {
            if (data == null) {
                return '-';
            }
            return formatter.dateComplete(data);
        },
        filterDate() {
            if (!this.meta.start_date) {
                return 'Filter Tanggal';
            } else if (!this.meta.end_date) {
                return formatter.dateComplete(this.meta.start_date);
            } else {
                return formatter.dateComplete(this.meta.start_date) + ' - ' + formatter.dateComplete(this.meta.end_date);
            }
        },
        generateFileName() {
            return `Entry User Report - ${this.formatDate(new Date())}.xls`;
        },
        async downloadExcelDetail() {
            excelDownloader(this.reports, this.generateFileName());
        },
    },
};
</script>

<style scoped>

</style>
